.App {
  text-align: center;
}
body {
  
  
  background-color: #efefef;
  
}
.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-family: 'RetroGaming';
  src:
  local('RetroGaming'),
   url(/public/font/RetroGaming.ttf) format('ttf');
}

.typing{
  white-space: nowrap;
  font-family: monospace;
  font-weight: bolder;
  font-size: 36px;
  color: #F58166;
  line-height: 1.7;
  position: absolute;
   left: 50%;
   top: 40%;
   -webkit-transform: translate(-50%, -50%);
   transform: translate(-50%, -50%);
 }

 .aboutUsText{
    font-family: monospace;
    
    font-size: 18px;
    color: black;
    line-height: 1.7;
    /* position: absolute;
     left: 50%;
     top: 30%;
     -webkit-transform: translate(-50%, -50%);
     transform: translate(-50%, -50%); */
   
 }

 .sendMail{
  color: #F58166;
  font-size: 1em;
 }

 .followMeText{
  color: gray;
  font-size: 1em;
  position: absolute;
   left: 50%;
   top: 48%;
   -webkit-transform: translate(-50%, -50%);
   transform: translate(-50%, -50%);
 }

 .notFoundText{
  color: black;
  font-size: 3em;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
 }

 .followMeIcon{
  
  font-size: 1em;
  position: absolute;
   left: 50%;
   top: 54%;
   -webkit-transform: translate(-50%, -50%);
   transform: translate(-50%, -50%);
 }
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
